import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import  DocsList from 'components/DocsList';
import { Layout } from 'layouts';
import  CategoriesBlock  from 'components/CategoriesBlock';
import styled from '@emotion/styled';
import { Link } from 'gatsby';


const Header = styled.div`
display: flex;
 padding-bottom: 2%;
 padding-top: 5%;
// z-value: 200;
justify-content: center;
//padding: 0% 0 2% 0;
//background: ${props => props.theme.colors.black.blue};
font-size: 2rem;

@media (max-width: 600px) {
    padding-top: 15%;
    padding-bottom: 5%;
  }
`;
const BlogPosts = styled.div`
display: flex;
position: relative ;
//grid-gap: 5px;
//padding-top: 5%;
flex-direction: column;

margin: auto;
//width: 80vw;


background: ${props => props.theme.colors.black.blue};
box-shadow: 0 4px 120px rgba(0,0,0,0.1);

//font-size: 2rem;

@media (max-width: 800px) {
//width: 95vw;

  }
`;

const Fspace = styled.div`
//display: flex;
height:1rem;
background: white;
min-height:25rem;
@media (max-width: 800px) {
    // padding-top: 10%;
    // padding-bottom: 5%;
    min-height:14rem;
  }
  @media (max-width: 300px) {
    min-height:10rem;
  }

`;
const Fwarp = styled.div`
display: flex;
flex-direction: column;
//height:20%;

@media (max-width: 800px) {
    // padding-top: 10%;
    // padding-bottom: 5%;
  }
`;
const Article = styled.div`
display: flex;
flex-direction: column;
//margin:2%;
background: white;
@media (max-width: 800px) {
    // padding-top: 10%;
    // padding-bottom: 5%;
  }
`;
const ContainerWrapper = styled.div`
position: relative;
display: flex;
justify-content:center;

//top:-5rem;
// overflow:hidden;
//   grid-template-rows: 1fr 1fr;
//   grid-template-columns: 1fr;
//   grid-template-areas: 
//   "a"
//   ;
//   grid-auto-flow: dense; 
@media (max-width: 800px) {
  //grid-template-rows: 1fr;
  }
`;
const Documentation = ({ data }) => {
  const { edges } = data.docs;
  //const { esdfdges1 } = data.docs;
  //var karr = edges.filter(({node}) => node.frontmatter.category == ono)
  

  return (
    <Layout>
      <Header>
      Documentation
      </Header>
      <Helmet title={'Limitless Documentations'} />
      <ContainerWrapper>
     
      <BlogPosts>
      {        
        edges.map(({ node }) => (
          <Fwarp>
          <Article>{node.frontmatter.category}</Article>

        <DocsList
          key={node.id}
          cover={node.frontmatter.cover.childImageSharp.fluid}
          path={node.frontmatter.path}
          title={node.frontmatter.title}
          date={node.frontmatter.date}
          //tags={node.frontmatter.tags}
          excerpt={node.excerpt}
        />

</Fwarp>
      ))
      }      
      </BlogPosts>
      {/* <BlogPosts>
      {        
        esdfdges1.map(({ node }) => (
<Fwarp>

        <DocsList
          key={node.id}
          cover={node.frontmatter.cover.childImageSharp.fluid}
          path={node.frontmatter.path}
          title={node.frontmatter.title}
          date={node.frontmatter.date}
          //tags={node.frontmatter.tags}
          excerpt={node.excerpt}
        />

</Fwarp>
      ))
      }      
      </BlogPosts> */}
      </ContainerWrapper>
      {/* <Fspace/> */}
    </Layout>
  );
};

export default Documentation;

Documentation.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            excerpt: PropTypes.string,
            frontmatter: PropTypes.shape({
              cover: PropTypes.object.isRequired,
              path: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired,
              date: PropTypes.string.isRequired,
              category: PropTypes.string.isRequired,
              tags: PropTypes.array,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
};

export const query = graphql`
  query {
    docs: allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/docs/**/*.md" }frontmatter: { published: { eq: false} } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 800)
          frontmatter {
            path
            date
            title
            category
            cover {
              childImageSharp {
                fluid(
                  maxWidth: 200
                  quality: 100
                  traceSVG: { color: "#2B2B2F" }
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    docs1: allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/docs/**/*.md" }frontmatter: { category: { eq: "RL"} } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 800)
          frontmatter {
            path
            date
            title
            cover {
              childImageSharp {
                fluid(
                  maxWidth: 200
                  quality: 100
                  traceSVG: { color: "#2B2B2F" }
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
  
`;
