import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import { Container } from 'layouts';


const Wrapper = styled.article`
  //margin: 0 10rem;
display: flex;
justify-content: stretch;
width:80vw;
border: 1px solid #e5e5e5;
box-shadow: 0 .5px 0 0 #ffffff inset, 0 2px 2px 0 #e0d9d9;
@media (max-width: 800px) {
  flex-direction: column;
  padding-bottom: 10%;
  box-shadow: 0 0.5px 0 0 #ffffff inset, 0 2px 2px 0 #e0d9d9;
  //border-radius: 1rem;
  width:100vw;
  z-index: 0;
}
background: #FFF;
//box-shadow: 0 4px 120px rgba(0,0,0,0.1);
transition: 0.1s;
//position:relative;
//z-index:10;
&:hover {
  
  //box-shadow: 0 5px 35px 0px rgba(0,0,0,.1);
  //box-shadow: 0 4px 120px rgba(0,0,0,0.1);
  //box-shadow: 0 1px #FFFFFF inset, 0 1px 3px rgba(34, 25, 25, 0.4);
  box-shadow: 0 0.5px 0 0 #ffffff inset, 0 2px 2px 0 #e0d9d9;

  transform: scale(1.02);
  //background: #f2edec;
  //border-radius: 1rem;
}
`;

const Image = styled.div`
  //margin: 2rem 2rem;
  //display:flex;
  //position: relative;
  //align-self: center;
  //box-shadow: ${props => props.theme.shadow.feature.small.default};
  //transition: ${props => props.theme.transitions.boom.transition};
  //border-radius: ${props => props.theme.borderRadius.default};
  height: 5rem;
  .gatsby-image-wrapper {
    //border-radius: ${props => props.theme.borderRadius.default};
    object-fit: contain;
    //position: relative;
    //margin-left: auto;
          margin-right: auto;
           width: 5rem;
           height: 5rem;
          //top: 2.5rem;
  }

  //flex-basis: 20rem;
  
  //max-width: 100%;
  //width: 10rem;
  //height: 100%;
  //object-fit: contain;

  // @media (max-width: 800px) {
  //   flex-basis: 100%;
  //   max-width: 100%;
  //   width: 100%;
  //   margin-bottom: 1.5rem;
  // }
  // @media (max-width: 500px) {
  //   min-height: 200px;
  // }
`;
const TitleLink = styled.div`
display: flex;
align-self:center;
justify-content:center;
flex-basis: 100vw;
padding-left: 2%;

@media (max-width: 800px) {
  padding-top: 0%;
  padding-bottom: 0%;
}
`;
const Title = styled.h1`
  //margin: 0;
`;
const LeftBlock = styled.div`
display: flex;  
justify-content:center;
flex-direction: column;
padding: 2%;
min-width: 50%;
margin-bottom: 1rem;
  h1 {
    font-size: 2rem;
    display: inline-block;
    color: ${props => props.theme.colors.black.lighter};
    transition: all ${props => props.theme.transitions.default.duration};
    &:hover {
      //color: ${props => props.theme.colors.primary.base};
    }
  }
  text-align: center;
`;
const RightBlock = styled.div`
display: flex;  
//padding: 2%;
//justify-content:center;
flex-direction: row;
//min-width: 50%;
//margin-bottom: 1rem;
//text-align: center;
`;
const DocsList = ({ path, cover, title }) => (
  <Container>
    <Wrapper>
    <RightBlock>
      <Image>
        <Link to={path} title={title}>
          <Img fluid={cover} />
        </Link>
      </Image>
      {/* <Date>{date}</Date> */}

   
           <TitleLink>
        <Link style = {{color:"#2b2523"}} to={path}>
          {title}</Link>
        </TitleLink>
        {/* <Information>

        {excerpt}     

         </Information> */}
      </RightBlock>  
    </Wrapper>
  </Container>
);

export default DocsList;

DocsList.propTypes = {
  cover: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  //excerpt: PropTypes.string,
  //date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  //tags: PropTypes.array.isRequired,
};
